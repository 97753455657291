<template>
    <v-btn icon @click.stop="onClick(true)" :loading="loading" :title="title" :to="to" :small="small">
        <v-icon v-if="isDone && doneIcon" :small="small">{{ doneIcon }}</v-icon>
        <v-icon v-else :small="small">{{ icon }}</v-icon>
        <v-dialog v-model="showDialog" max-width="500px">
            <v-card>
                <v-card-title class="headline">{{ msg }}</v-card-title>
                <v-card-actions>
                    <v-btn text @click.stop="showDialog = false">Cancel</v-btn>
                    <v-btn text @click.stop="onClick(false)">OK</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-btn>
</template>

<script>
export default {
    name: 'BT-Single-Action',
    data: function() {
        return {
            showDialog: false
        }
    },
    props: {
        confirm: {
            type: Boolean,
            default: false
        },
        isOnceOff: {
            type: Boolean,
            default: false
        },
        isDone: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            default: 'mdi-arrow-right-circle'
        },
        doneIcon: {
            type: String,
            default: 'mdi-check'
        },
        title: {
            type: String,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        },
        msg: {
            type: String,
            default: 'Are you sure?'
        },
        doneColor: {
            type: String,
            default: null
        },
        small: {
            type: Boolean,
            default: true
        },
        to: null,
    },
    methods: {
        onClick(isFirst = false) {
            if (isFirst && this.confirm === true) {
                this.showDialog = true;
                return;
            }
            if (!this.isOnceOff || this.isOnceOff && !this.isDone) {
                this.$emit('click');
            }
        }
    }    
}
</script>